<template>
	<!--sitemap pop-->
	<div class="sitemap_popup">
                
		<!--공급기업pop-->
		<div class="title_wrap" :class="this.$store.state.userInfo.mberDivCd == '31' ? '' : 'display-none'">
			<img src="/images/logo.png">
			<h2>공급기업 담당 Site Map</h2>                    
		</div>
		<div class="sitemap-wrap" :class="this.$store.state.userInfo.mberDivCd == '31' ? '' : 'display-none'">
			<div class="site-link">
				<div class="site-link-title color1">서비스 소개</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MAN001M01')">서비스 소개</a></li>
				</ul>

				<div class="site-link-title color2" style="margin-top: 50px;">기술인재 정보</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('SRH401M01')">소속인재 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ401M03')">소속인재 프로젝트 참여 현황</a></li>
				</ul>

				<div class="site-link-title color4" style="margin-top: 27px;">하이프로 이용방법</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업 이용방법</a></li>
				</ul>
			</div>

			<div class="site-link">
				<div class="site-link-title color6">공급기업 지정 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ406M01')">전체 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ407M01')">진행단계별 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ402M01')">소속인재 프로젝트 지원 현황</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ401M01')">소속인재 프로젝트 참여 현황</a></li> -->
				</ul>

				<div class="site-link-title color8" style="margin-top: 85px;">일반 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ405M01')">프로젝트 찾기</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ403M01')">소속인재 프로젝트 지원 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ404M01')">소속인재 프로젝트 인터뷰 현황</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ401M02')">소속인재 프로젝트 참여 현황</a></li> -->
				</ul>
			</div>

			<div class="site-link">
				<div class="site-link-title noti color9">알림</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP103M01')">알림 메세지</a></li>
				</ul>

				<div class="site-link-title my-p color9" style="margin-top: 50px;">마이페이지</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP401M01')">My Page</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MYP402M01')">정보관리</a></li>
					<li><a href="javascript:void(0);">로그아웃</a></li>
				</ul>
			</div>
		</div>

		<!--기술인재pop-->  
		<div class="title_wrap" :class="this.$store.state.userInfo.mberDivCd == '11' ? '' : 'display-none'">
			<img src="/images/logo.png">
			<h2>기술인재 Site Map</h2>                    
		</div>
		<div class="sitemap-wrap" :class="this.$store.state.userInfo.mberDivCd == '11' ? '' : 'display-none'">
			<div class="site-link">
				<div class="site-link-title color1">서비스 소개</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MAN001M01')">서비스 소개</a></li>
				</ul>

				<div class="site-link-title color2" style="margin-top: 50px;">하이프로 이용방법</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업 이용방법</a></li>
				</ul>

				<div class="site-link-title color4" style="margin-top: 50px;">적립 포인트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PIT101M01')">내 적립 포인트</a></li>
				</ul>
			</div>

			<div class="site-link">
				<div class="site-link-title color6">프로젝트 정보</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ101M01')">프로젝트 찾기</a></li>
				</ul>
				<div class="site-link-title color8" style="margin-top: 50px;">내 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ102M01')">제안단계 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ102M03')">참여예정 · 참여중 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ102M04')">완료 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ103M01')">프로젝트 인터뷰 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ104M01')">관심/지원 프로젝트</a></li>
				</ul>					
			</div>

			<div class="site-link">
				<div class="site-link-title noti color9">알림</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP103M01')">알림 메세지</a></li>
				</ul>

				<div class="site-link-title my-p color9" style="margin-top: 50px;">마이페이지</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP101M01')">My Page</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PIT101M01')">내 적립 포인트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MYP102M01')">개인정보관리</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRF101M01')">내 프로필 관리</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRF103M01')">내 이력서 열람기업</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRF103M02')">관심인재 등록기업</a></li>
					<li><a href="javascript:void(0);">로그아웃</a></li>
				</ul>
			</div>
		</div>

		<!--수요기업/관리pop-->              
		<div class="title_wrap" :class="this.$store.state.userInfo.mberDivCd == '21' ? '' : 'display-none'">
			<img src="/images/logo.png">
			<h2>수요기업 / 관리총괄 Site Map</h2>                    
		</div>
		<div class="sitemap-wrap" :class="this.$store.state.userInfo.mberDivCd == '21' ? '' : 'display-none'">
			<div class="site-link">
				<div class="site-link-title color1">서비스 소개</div>
				<ul class="site-link-list">
					<li class="mb-17"><a href="javascript:void(0);" @click="pageMove('MAN001M01')">서비스 소개</a></li>
				</ul>

				<div class="site-link-title color2" style="margin-top: 50px;">하이프로 이용방법</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업 이용방법</a></li>
				</ul>

				<div class="site-link-title color4" style="margin-top: 50px;">적립 포인트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PIT201M01')">내 적립 포인트</a></li>
				</ul>
			</div>

			<div class="site-link">
				<div class="site-link-title color5">공급기업 지정 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ201M01')">전체 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ203M01')">진행단계별 프로젝트</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ202M01')">소속인재 프로젝트 참여 현황</a></li> -->
				</ul>

				<div class="site-link-title color6" style="margin-top: 20px;">일반 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ204M01')">전체 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ205M01')">진행단계별 프로젝트</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ206M01')">소속인재 프로젝트 참여 현황</a></li> -->
				</ul>

				<div class="site-link-title color8" style="margin-top: 50px;">기술인재 정보</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('SRH201M01')">소속인재 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ207M01')">소속인재 프로젝트 참여 현황</a></li>
				</ul>
			</div>
			<div class="site-link">
				<div class="site-link-title noti color9">알림</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP103M01')">알림 메세지</a></li>
				</ul>

				<div class="site-link-title my-p color9" style="margin-top: 68px;">마이페이지</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP201M01')">My Page</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MYP202M01')">정보관리</a></li>
					<li><a href="javascript:void(0);">로그아웃</a></li>
				</ul>
			</div>
		</div>

		<!--수요기업/프로젝트pop-->             
		<div class="title_wrap" :class="this.$store.state.userInfo.mberDivCd == '22' ? '' : 'display-none'">
			<img src="/images/logo.png">
			<h2>수요기업 / 프로젝트 담당 Site Map</h2>                    
		</div>
		<div class="sitemap-wrap" :class="this.$store.state.userInfo.mberDivCd == '22' ? '' : 'display-none'" style="gap: 20px !important;" >
			<div class="site-link">
				<!--site-link-title size 중복 class 확인하세요-->
				<div class="site-link-title sm color1">서비스 소개</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MAN001M01')">서비스 소개</a></li>
				</ul>

				<div class="site-link-title sm color2" style="margin-top: 50px;">하이프로 이용방법</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재 이용방법</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업 이용방법</a></li>
				</ul>

				<div class="site-link-title sm color4" style="margin-top: 35px;">기술인재 정보</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('SRH301M01')">기술인재 찾기</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('SRH302M01')">소속인재 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ308M03')">소속인재 프로젝트 참여 현황</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRF301M01')">관심인재</a></li>
				</ul>
			</div>

			<div class="site-link">
				<div class="site-link-title sm color6">공급기업 지정 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ301M01')">프로젝트 등록</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ309M01')">전체 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ307M01')">진행단계별 프로젝트</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ308M01')">소속인재 프로젝트 참여 현황</a></li> -->
				</ul>

				<div class="site-link-title sm color8" style="margin-top: 50px;">일반 프로젝트</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('PRJ301M02')">프로젝트 등록</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ302M04')">진행단계별 프로젝트</a></li>
					<!-- <li><a href="javascript:void(0);" @click="pageMove('PRJ308M02')">소속인재 프로젝트 참여 현황</a></li> -->
					<li><a href="javascript:void(0);" @click="pageMove('PRJ305M01')">프로젝트 지원인재</a></li>
					<li class="mb-6"><a href="javascript:void(0);" @click="pageMove('PRJ306M01')">프로젝트 인터뷰 현황</a></li>
				</ul>				
			</div>
			
			<div class="site-link">
				<div class="site-link-title noti sm color9">알림</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP103M01')">알림 메세지</a></li>
				</ul>

				<div class="site-link-title my-p sm color9" style="margin-top: 122px;">마이페이지</div>
				<ul class="site-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('MYP301M01')">My Page</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ302M04')">일반 구인 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('PRJ307M01')">공급기업 지정 프로젝트</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MYP302M01')">정보관리</a></li>
					<li><a href="javascript:void(0);">로그아웃</a></li>
				</ul>
			</div>
		</div>


		<!--박스메뉴 공통-->
		<div class="copy-wrap">
			<div class="foot-link">
				<div class="foot-link-title">하이프로</div>
				<ul class="foot-link-list">
					<li><a target="_blank" href="https://www.pronsolution.com/company">회사소개</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('')">사이트맵</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MAN002M01')">이용약관</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MAN002M02')">개인정보 처리방침</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title">
					<a href="javascript:void(0);" @click="pageMove('FAQ001M01')">FAQ</a>
				</div>
				<ul class="foot-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ004M01')">이용요금</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ005M01')">포인트 제도</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title">
					<a href="javascript:void(0);" @click="pageMove('BBS001M01')">뉴스센터</a>
				</div>
				<ul class="foot-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('BBS002M01')">공지사항</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('BBS003M01')">하이프로 뉴스</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title">고객센터</div>
				<p class="mb-3">02-780-3800 (10:00 - 18:00, 공휴일 제외)</p>
				<p class="mb-5">highpro@pron.co.kr</p>
				<button type="button" class="btn-inquiry" @click="pageMove('CTS001M01')">문의하기</button>
			</div>
		</div>
		<button type="button" class="btn-close" @click="$emit('close')">닫기</button>
	</div> 
	
</template>

<script>
export default {
	data() {
		return {
			
		}
	},

	methods: {
		pageMove(name) {
			// console.log('name', name);
			this.$emit('close', false);
			this.$router.move(name);
		},
	}
}
</script>